/* FONTS */

html body {
  background-color: #000;
  text-align: center;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
}

/* Header */

div#header {
  text-shadow: 2px 0 #000;
  margin: 20px;;
}

/* Generic */

form { margin: 30px 15px; }

ul {
  margin: 20px 0;
  list-style-type: none;
}

ul li a {
  color: #0dcaf0!important;
}

h1, h2 { font-family: Poppins, Arial, Helvetica, sans-serif; }

h1 { color: white!important; }
h2 { color: #ddd!important; }

a {
  color: #FFF!important;
  text-decoration: none!important;
}

a:hover {
  color: #CCC!important;
}

@media (min-width: 1200px) {
  h2, .h2 { font-size: 1.8rem!important; }
}

p {
  color: white;
}

div.bg-dark {
  background-color: black!important;
}

.btn { font-weight: bold!important; }

.halloween-btn {
  background: darkorange!important;
  color: black!important;
  border: 1px solid black!important;
  transition: all 0.3s ease-in-out;
}

.halloween-btn:hover {
  transform: scale(1.05);
  background: #ff7300!important;
  box-shadow: 0 0 10px rgba(255, 115, 0, 0.5);
}

canvas {
  border: 2px solid black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  canvas {
    width: 275px;
    height: 300px;
  }
}
@media (min-width: 769px) {
  canvas {
    width: 469px;
    height: 512px;
  }
}

/* Footer */

span.sc, span.made-with-love {
  font-size: 0.8rem;;
}

/* Connect Zone */
div.connect-zone { margin-top: 10px; }
div.connect-zone div { justify-content: center; }
div.connect-zone button[data-testid = "rk-connect-button"] { background: none; color: darkorange; border: 2px solid darkorange; border-radius: 5px; }
div.connect-zone button[data-testid = "rk-account-button"] { background: linear-gradient(180deg, #FFF 0%, #EEE 100%)!important; }
div[aria-labelledby="rk_account_modal_title"] h1 { color: black!important; }